// @flow

import * as React from 'react'

import LoadingContainer from '../containers/LoadingContainer'

function LoadingRoute () {
  return <LoadingContainer />
}

export default LoadingRoute
