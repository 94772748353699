// @flow

import { createSlice } from 'redux-starter-kit'

const initialState = {
  mobileOpen: false,
  expanded: {},
  selected: {},
  fetching: {},
  success: {},
  error: {},
  editing: {},
  editSuccess: {},
  editError: {}
}

function setMobileOpen (state, action) {
  const { payload } = action

  return { ...state, mobileOpen: payload }
}

function setExpanded (state, action) {
  const { id, expanded } = action.payload

  state.expanded[id] = expanded
}

function setSelectedId (state, action) {
  const { id, selected } = action.payload

  state.selected[id] = selected
}

function addCollectionRequest (state, action) {
  const { id } = action.payload

  state.fetching[id] = true
  state.error[id] = false
  state.success[id] = false
}

function addCollectionSuccess (state, action) {
  const { id } = action.payload
  console.log(action.payload)
  state.fetching[id] = false
  state.error[id] = false
  state.success[id] = true
}

function addCollectionFailure (state, action) {
  const { id } = action.payload

  state.fetching[id] = false
  state.error[id] = true
  state.success[id] = false
}

function editCollectionRequest (state, action) {
  const { id } = action.payload

  state.editing[id] = true
  state.editError[id] = false
  state.editSuccess[id] = false
}

function editCollectionSuccess (state, action) {
  const { id } = action.payload

  state.editing[id] = false
  state.editError[id] = false
  state.editSuccess[id] = true
}

function editCollectionFailure (state, action) {
  const { id } = action.payload

  state.editing[id] = false
  state.editError[id] = true
  state.editSuccess[id] = false
}

function removeCollectionRequest (state, action) {
  const { id } = action.payload

  state.fetching[id] = true
  state.error[id] = false
  state.success[id] = false
}

function removeCollectionSuccess (state, action) {
  const { id } = action.payload

  state.fetching[id] = true
  state.error[id] = false
  state.success[id] = false
}

function removeCollectionFailure (state, action) {
  const { id } = action.payload

  state.fetching[id] = true
  state.error[id] = false
  state.success[id] = false
}

function removeAllCollectionsRequest (state, action) {
  return state
}

function removeAllCollectionsSuccess (state, action) {
  return initialState
}

function removeAllCollectionsFailure (state, action) {
  console.warn(action.payload)

  return state
}

const catalog = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    setMobileOpen,
    setExpanded,
    setSelectedId,
    addCollectionRequest,
    addCollectionSuccess,
    addCollectionFailure,
    editCollectionRequest,
    editCollectionSuccess,
    editCollectionFailure,
    removeCollectionRequest,
    removeCollectionSuccess,
    removeCollectionFailure,
    removeAllCollectionsRequest,
    removeAllCollectionsSuccess,
    removeAllCollectionsFailure
  }
})

export default catalog
