// @flow

import { ofType } from 'redux-observable'
import { map, catchError } from 'rxjs/operators'
import { of } from 'rxjs'
import type { Observable } from 'rxjs'

import type { Action, Epic } from '../types'
import shipDuck from './shipDuck'

const editShipsEpic: Epic = (
  action$: Observable<Action>,
  state$: mixed,
  firestore: Function
) =>
  action$.pipe(
    ofType(shipDuck.actions.editShipRequest),
    map(async ({ payload }) => {

      return payload
    }),
    map(result => shipDuck.actions.editShipSuccess(result)),
    catchError(error => {
      console.warn(error.message)
      return of(shipDuck.actions.editShipFailure(error.message))
    })
  )

export default editShipsEpic
