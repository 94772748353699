// @flow

import { ofType } from 'redux-observable'
import { map, catchError, filter, withLatestFrom } from 'rxjs/operators'
import { of } from 'rxjs'
import type { Observable } from 'rxjs'

import type { Action, Epic } from '../types'
import sourcesDuck from './sourcesDuck'
import mapDuck from './mapDuck'
import { getCollectionBbox } from '../redux/catalogSelector'

const fitBoundsEpic: Epic = (
  action$: Observable<Action>,
  state$: Observable<mixed>
) =>
  action$.pipe(
    ofType(sourcesDuck.actions.addSource),
    filter(action => action.payload.fitBounds === true),
    withLatestFrom(state$),
    map(([action, state]) =>
      getCollectionBbox(state, { collectionId: action.payload.collectionId })
    ),
    map(bbox => mapDuck.actions.fitBounds({ bbox })),
    catchError(error => {
      console.warn(error.message)
      return of({ type: 'error' })
    })
  )

export default fitBoundsEpic
