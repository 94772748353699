// @flow

import { ofType } from 'redux-observable'
import { map, catchError } from 'rxjs/operators'
import { of } from 'rxjs'
import type { Observable } from 'rxjs'

import type { Action, Epic } from '../types'
import catalogDuck from './catalogDuck'

const removeCollectionEpic: Epic = (
  action$: Observable<Action>,
  state$: mixed,
  firestore: Function
) =>
  action$.pipe(
    ofType(catalogDuck.actions.removeCollectionRequest),
    map(({ payload }) => {
      const { id } = payload
      const { uid } = state$.value.firebase.auth

      firestore
        .collection('users')
        .doc(uid)
        .collection('collections')
        .doc(id)
        .delete()

      return payload
    }),
    map(result => catalogDuck.actions.removeCollectionSuccess(result)),
    catchError(error => {
      console.warn(error.message)
      return of(catalogDuck.actions.removeCollectionFailure(error.message))
    })
  )

export default removeCollectionEpic
