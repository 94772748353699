// @flow

import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Route } from 'react-router-dom'

import NotAuthenticatedNavigationContainer from '../navigation/NotAuthenticatedNavigationContainer'
import AuthenticatedNavigationContainer from '../navigation/AuthenticatedNavigationContainer'
import LoadingRoute from '../routes/LoadingRoute'
import { getAuth } from '../redux/authSelector'

function NavigationContainer () {
  const auth = useSelector(state => getAuth(state))

  return (
    <Fragment>
      {auth && !auth.isLoaded ? (
        <Route path='/' exact component={LoadingRoute} />
      ) : auth && auth.isEmpty ? (
        <NotAuthenticatedNavigationContainer />
      ) : (
        <AuthenticatedNavigationContainer />
      )}
    </Fragment>
  )
}

export default NavigationContainer
