// @flow

import getCacheName from '../utils/getCacheName'

async function addItemToCache (item: { id: string, assets: mixed }) {
  const { assets } = item
  const urls = Object.values(assets).map(asset => asset.href)
  const cacheName = getCacheName()
  const cache = await window.caches.open(cacheName)
  try {
    await cache.addAll(urls)

    // Mapbox accesses overlay data in its own cache
    const mapboxCache = await window.caches.open('mapbox-tiles')
    await mapboxCache.addAll(urls)

    return item
  } catch {
    return { ...item, error: true }
  }
}

export default addItemToCache
