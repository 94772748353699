// @flow

import { createSlice } from 'redux-starter-kit'

const initialState = {
  open: false,
  steps: [
    {
      selector: '[data-tour="draw"]',
      content: 'Draw an area of interest to get started'
    }
  ]
}

function setOpen (state, action) {
  const { payload } = action

  state.open = payload
}

const tour = createSlice({
  name: 'tour',
  initialState,
  reducers: {
    setOpen
  }
})

export default tour
