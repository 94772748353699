// @flow

import { createSlice } from 'redux-starter-kit'

function addSelection (state, action) {
  const { id, collectionId } = action.payload
  const selection = {
    id,
    collectionId,
    selected: true
  }

  return { ...state, [id]: selection }
}

function removeSelection (state, action) {
  const { id, collectionId } = action.payload
  const selection = {
    id,
    collectionId,
    selected: false
  }

  return { ...state, [id]: selection }
}

function toggleSelection (state, action) {
  const { id, collectionId } = action.payload
  const prevSelection = state[id]
  const selection =
    prevSelection && prevSelection.selected
      ? {
          id,
          collectionId,
          selected: false
        }
      : {
          id,
          collectionId,
          selected: true
        }

  return { ...state, [id]: selection }
}

const selections = createSlice({
  name: 'selections',
  initialState: {},
  reducers: {
    addSelection,
    removeSelection,
    toggleSelection
  }
})

export default selections
