// @flow

import getCacheName from '../utils/getCacheName'

async function deleteItemFromCache (item: { id: string, assets: mixed }) {
  const { assets } = item

  const urls = Object.values(assets).map(asset => asset.href)

  const cacheName = getCacheName()
  const cache = await window.caches.open(cacheName)
  urls.forEach(async url => await cache.delete(url))
  console.log(item.id)
  return item
}

export default deleteItemFromCache
