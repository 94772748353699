import dayjs from './utils/dayjs'

// Firebase
export const apiKey = process.env.REACT_APP_API_KEY
export const authDomain = process.env.REACT_APP_AUTH_DOMAIN
export const databaseURL = process.env.REACT_APP_DATABASE_URL
export const projectId = process.env.REACT_APP_PROJECT_ID
export const storageBucket = process.env.REACT_APP_STORAGE_BUCKET
export const messagingSenderId = process.env.REACT_APP_MESSAGING_SENDER_ID
export const appId = process.env.REACT_APP_APP_ID
export const firebaseConfig = {
  apiKey, authDomain, databaseURL, projectId, storageBucket, messagingSenderId
}


// Mapbox
export const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
export const defaultViewport = {
  latitude: 55,
  longitude: -58.5,
  zoom: 4,
}

// Api
export const apiUrl = 'https://jsonplaceholder.typicode.com/todos/1'
export const sentinelUrl = 'https://services.sentinel-hub.com/ogc/wfs/6179cd9a-6ff5-43a5-8f01-8aef07e9f211?version=2.0.0&request=GetFeature&typeNames=DSS3&maxFeatures=100&outputFormat=application%2Fjson&maxcc=60&srsNAME=EPSG%3A4326&service=WFS&bbox=-53.31965915561267,47.41303749611413,-52.253759177937724,48.10486687157464'
export const eoUrl = ({ acquisitionMode='EW', polarization='DH', timeTo = dayjs.utc().toISOString() }) => `https://eocloud.sentinel-hub.com/index/s1/v1/search?expand=true&timefrom=2019-07-20T00:00:00.000Z&timeto=${timeTo}&maxcc=1&maxcount=51&offset=0&productType=GRD&acquisitionMode=${acquisitionMode}&polarization=${polarization}`

const feature = "{\"type\":\"Polygon\",\"crs\":{\"type\":\"name\",\"properties\":{\"name\":\"urn:ogc:def:crs:EPSG::4326\"}},\"coordinates\":[[[-54.98931884765626,46.5607488448596],[-51.15509033203126,46.5607488448596],[-51.15509033203126,48.372672242291294],[-54.98931884765626,48.372672242291294],[-54.98931884765626,46.5607488448596]]]}"

export const eoParameters = {"credentials":"omit","headers":{"accept":"application/json, text/plain, */*","accept-crs":"EPSG:4326","content-type":"application/json;charset=UTF-8","sec-fetch-mode":"cors"},"referrer":"https://apps.sentinel-hub.com/","referrerPolicy":"origin","body":feature,"method":"POST","mode":"cors"}

// Ice classes
export const iceClasses = [
  {
    value: 'PC1',
    label: 'PC1',
    description: 'Year-round operation in all polar waters'
  },
  {
    value: 'PC2',
    label: 'PC2',
    description: 'Year-round operation in moderate multi-year ice'
  },
  {
    value: 'PC3',
    label: 'PC3',
    description:
      'Year-round operation in second year ice, which may include multi-year inclusions'
  },
  {
    value: 'PC4',
    label: 'PC4',
    description:
      'Year-round operation in thick first year ice, which may include old ice inclusions'
  },
  {
    value: 'PC5',
    label: 'PC5',
    description:
      'Year-round operation in medium first year ice, which may include old ice inclusions'
  },
  {
    value: 'PC6',
    label: 'PC6',
    description:
      'Summer/autumn operation in medium first-year ice, which may include old ice inclusions'
  },
  {
    value: 'PC7',
    label: 'PC7',
    description:
      'Summer/autumn operation in thin first-year ice, which may include old ice inclusions'
  },
  {
    value: 'ICE-1A*/E4',
    label: 'ICE-1A*/E4',
    description: 'First-year ice to 1.0 m'
  },
  {
    value: 'ICE-1A/E3',
    label: 'ICE-1A/E3',
    description: 'First-year ice to 0.8 m'
  },
  {
    value: 'ICE-1B/E2',
    label: 'ICE-1B/E2',
    description: 'First-year ice to 0.6 m'
  },
  {
    value: 'ICE-1C/E1',
    label: 'ICE-1C/E1',
    description: 'First-year ice to 0.4 m'
  },
  { value: 'ICE-C/E', label: 'ICE-C/E', description: 'Light ice conditions' },
  {
    value: 'None',
    label: 'None',
    description: 'Ice-free/open water conditions'
  }
]
