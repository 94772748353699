// @flow

import { configureStore } from 'redux-starter-kit'
import { combineReducers } from 'redux'
import { combineEpics, createEpicMiddleware } from 'redux-observable'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'
import { persistStore, persistReducer, PURGE } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import withReduxEnhancer from 'addon-redux/enhancer'

import firebase from './firebase'
import addCollectionEpic from './addCollectionEpic'
import cache from './cacheDuck'
import cacheEpic from './cacheEpic'
import catalog from './catalogDuck'
import collectionDuck from './collectionDuck'
import deleteCacheEpic from './deleteCacheEpic'
import editCollectionEpic from './editCollectionEpic'
import editShipEpic from './editShipEpic'
import editDuck from './editDuck'
import fitBoundsEpic from './fitBoundsEpic'
import hover from './hoverDuck'
import map from './mapDuck'
import removeCollectionEpic from './removeCollectionEpic'
import removeAllCollectionsEpic from './removeAllCollectionsEpic'
import selections from './selectionsDuck'
import shipDuck from './shipDuck'
import sourcesDuck from './sourcesDuck'
import tourDuck from './tourDuck'

const persistConfig = {
  key: 'root',
  storage,
  // blacklist: ['cache'],
  whitelist: process.env === 'development' ? ['ship'] : ['cache', 'ship']
}

const firestore = firebase.firestore()
const rootEpic = (...args) =>
  combineEpics(
    addCollectionEpic,
    cacheEpic,
    editCollectionEpic,
    editShipEpic,
    deleteCacheEpic,
    fitBoundsEpic,
    removeCollectionEpic,
    removeAllCollectionsEpic
  )(...args, firestore)

const epicMiddleware = createEpicMiddleware()

// workaround for CORE-281
// const reactotronEnhancer = __DEV__
//   ? reactotron.createEnhancer()
//   : store => store

const reducers = combineReducers({
  cache: cache.reducer,
  catalog: catalog.reducer,
  collection: collectionDuck.reducer,
  edit: editDuck.reducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  hover: hover.reducer,
  map: map.reducer,
  selections: selections.reducer,
  ship: shipDuck.reducer,
  sources: sourcesDuck.reducer,
  tour: tourDuck.reducer
})

const rootReducer = (state, action) => {
  if (action.type === PURGE) {
    console.log(PURGE)
    state = undefined
  }

  return reducers(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: [epicMiddleware],
  devTools: true,
  enhancers: [withReduxEnhancer]
  // enhancers: [reactotronEnhancer]
})

// TODO:
// https://github.com/reduxjs/redux-starter-kit/blob/dd2a81e8dd807b781a568f0195e619cb2fc99824/docs/tutorials/advanced-tutorial.md#store-setup-and-hmr
// if (process.env.NODE_ENV === 'development' && module.hot) {
//   module.hot.accept('./rootReducer', () => {
//     const newRootReducer = require('./rootReducer').default
//     store.replaceReducer(newRootReducer)
//   })
// }

epicMiddleware.run(rootEpic)

export const persistor = persistStore(store)

window.firestore = firestore
window.store = store

export default store
