// @flow

import { ofType } from 'redux-observable'
import { map, switchMap, catchError, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import type { Observable } from 'rxjs'
import { fromFetch } from 'rxjs/fetch'
import getBbox from '@turf/bbox'
import dayjs from 'dayjs'
import intersect from '@turf/intersect'
import { Geometry } from 'wkx'
import { toMercator } from '@turf/projection'

import { eoUrl, eoParameters } from '../config'
import type { Action, Epic } from '../types'
import catalog from './catalogDuck'

function getIdFromPath (path: string) {
  const pathSplit = path.split('/')
  const safeId = pathSplit[pathSplit.length - 1]
  const id = safeId.split('.SAFE')[0]

  return id
}

const defaultHeight = 1024
const defaultWidth = 1024

function getUrlFromBboxAndDatetime ({
  feature,
  datetime,
  bbox,
  layers = 'S1-EW2',
  height = defaultHeight,
  width = defaultWidth
}) {
  // Need to request image from SentinelHub in EPSG:3857 to prevent projection errors when
  // rendering in Mapbox.
  const srs = 'EPSG:3857'
  const mercatorBbox = [
    ...toMercator([bbox[0], bbox[1]]),
    ...toMercator([bbox[2], bbox[3]])
  ]
  const mercatorFeature = toMercator(feature)

  const service = 'WMS'
  const request = 'GetMap'
  const name = 'Sentinel-1'
  const format = 'image/png'
  const transparent = true
  const maxcc = 100
  const date = dayjs(datetime).format('YYYY-MM-DD')
  const time = `${date}/${date}/P1D`
  const baseUrl = `https://services.sentinel-hub.com/ogc/wms/6179cd9a-6ff5-43a5-8f01-8aef07e9f211`
  const version = '1.1.1'
  const showLogo = false
  const wkt = Geometry.parseGeoJSON(mercatorFeature.geometry).toWkt()

  const url = `${baseUrl}?service=${service}&request=${request}&layers=${layers}&name=${name}&format=${format}&transparent=${String(
    transparent
  )}&version=${version}&showLogo=${String(
    showLogo
  )}&maxcc=${maxcc}&height=${height}&width=${width}&time=${encodeURIComponent(
    time
  )}&srs=${encodeURIComponent(srs)}&bbox=${encodeURIComponent(
    mercatorBbox
  )}&geometry=${wkt}`

  return url
}

function serializeGeometry (geometry: mixed) {
  const coordinates = JSON.stringify(geometry.coordinates)
  const serializedGeometry = {
    ...geometry,
    coordinates,
    serialized: true
  }

  return serializedGeometry
}

const addCollectionEpic: Epic = (
  action$: Observable<Action>,
  state$: mixed,
  firestore: Function
) =>
  action$.pipe(
    ofType(catalog.actions.addCollectionRequest),
    map(action => {
      // Create STAC collection entry
      const {
        payload: { label, feature, collectionId, type }
      } = action
      const { geometry } = feature
      const { uid } = state$.value.firebase.auth

      const bbox = getBbox(feature)

      const collectionLabel = label

      const collection = {
        id: collectionId,
        label: collectionLabel,
        type,
        bbox,
        geometry: serializeGeometry(geometry),
        userId: uid,
        assetIds: ['png'],
        created: new Date()
      }

      firestore
        .collection('users')
        .doc(uid)
        .collection('collections')
        .doc(collectionId)
        .set(collection)

      return { ...action, collectionId, bbox }
    }),
    switchMap(({ payload, collectionId, bbox }) => {
      // Fetch items from sentinel hub
      const { feature } = payload
      const { geometry } = feature
      const { uid } = state$.value.firebase.auth
      return fromFetch(eoUrl({}), {
        ...eoParameters,
        body: JSON.stringify(geometry)
      }).pipe(
        switchMap(response => response.json()),
        tap(console.log),
        // Create STAC item entries
        map(result =>
          addStacItem({
            result,
            uid,
            firestore,
            collectionId,
            bbox,
            searchFeature: feature
          })
        )
      )
    }),
    map(result => {
      return catalog.actions.addCollectionSuccess(result)
    }),
    catchError(error => {
      console.warn(error.message)
      return of(catalog.actions.addCollectionFailure(error.message))
    })
  )

export default addCollectionEpic

function addStacItem ({ result, uid, firestore, collectionId, searchFeature }) {
  const collectionLabel = 'Area of interest'
  const { tiles } = result

  // Remove duplicate results
  const uniqueTiles = Object.values(
    tiles.reduce((acc, tile) => {
      acc[tile.sensingTime] = tile
      return acc
    }, {})
  )

  // Create STAC metadata
  uniqueTiles.forEach(tile => {
    const { pixelEnvelope, tileDrawRegionGeometry, ...data } = tile
    const itemId = getIdFromPath(data.pathFragment)

    // In case multiple regions overlap same scene
    const uniqueId = `${collectionId}--${itemId}`

    const bbox = getBbox(tileDrawRegionGeometry)
    // Need to serialize coordinates, as firestore does not accept
    // nested arrays
    const intersectedFeature = intersect(searchFeature, tileDrawRegionGeometry)

    const coordinates = JSON.stringify(intersectedFeature.geometry.coordinates)
    const geometry = {
      ...intersectedFeature.geometry,
      coordinates,
      serialized: true
    }
    const datetime = data.sensingTime
    const url = getUrlFromBboxAndDatetime({
      bbox,
      datetime,
      feature: intersectedFeature
    })
    const assets = {
      png: {
        href: url,
        size: data.area * 10000
      }
    }

    const item = {
      id: uniqueId,
      collection: collectionId,
      name: collectionLabel,
      bbox: [bbox[0], bbox[2], bbox[1], bbox[3]],
      geometry,
      properties: { ...data, datetime },
      assets
    }

    firestore
      .collection('users')
      .doc(uid)
      .collection('collections')
      .doc(collectionId)
      .collection('items')
      .doc(itemId)
      .set(item)
  })

  return result
}
