// @flow

import { createSlice } from 'redux-starter-kit'

function updateHover (state, action) {
  const { payload } = action

  return { ...state, ...payload }
}

const hover = createSlice({
  name: 'hover',
  initialState: {},
  reducers: {
    updateHover
  }
})

export default hover
