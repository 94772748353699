// @flow

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import NavigationContainer from './navigation/NavigationContainer'
import { getUserQuery, getUserCollectionsQuery, getUserLabelsQuery } from './redux/firestoreSelector'

import art from './components/art'

console.log(art, "font-family:monospace; color:#005da6;")

function App() {
  useFirestoreConnect(useSelector(getUserQuery))
  useFirestoreConnect(useSelector(getUserCollectionsQuery))
  useFirestoreConnect(useSelector(getUserLabelsQuery))
  const query = useMemo(() => {
    return { collection: 'layers' }
  }, [])
  useFirestoreConnect(query)

  return (
    <NavigationContainer />
  );
}

export default App;
