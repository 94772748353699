function getCacheName () {
  const origin = window.location.origin
  const cacheName = `workbox-precache-v2-${origin}/`

  return cacheName
}

export async function addToCache (urls) {
  const cacheName = getCacheName()
  const cache = await window.caches.open(cacheName)
  await cache.addAll(urls)
  return urls
}

export default getCacheName
