import { createSlice } from 'redux-starter-kit'

function makeUniqueId ({ id, collection }) {

  return id
}

function closeCacheContainer (state, action) {
  return { ...state, open: false }
}

function openCacheContainer (state, action) {
  const { collectionId } = action.payload

  return { ...state, open: true, collectionId }
}

function fetchCacheRequest (state, action) {
  const { id, collection } = action.payload

  const uniqueId = makeUniqueId({ id, collection })

  state.fetching[uniqueId] = true
  state.error[uniqueId] = false
  state.success[uniqueId] = false
}

function fetchCacheSuccess (state, action) {
  const { id, collection } = action.payload

  const uniqueId = makeUniqueId({ id, collection })

  state.fetching[uniqueId] = false
  state.error[uniqueId] = false
  state.success[uniqueId] = true
}

function fetchCacheFailure (state, action) {
  const { id, collection } = action.payload

  const uniqueId = makeUniqueId({ id, collection })

  state.fetching[uniqueId] = false
  state.error[uniqueId] = true
  state.success[uniqueId] = false
}

function deleteCacheRequest (state, action) {
  const { id, collection } = action.payload

  const uniqueId = makeUniqueId({ id, collection })

  state.deleting[uniqueId] = true
  state.deleteError[uniqueId] = false
}

function deleteCacheSuccess (state, action) {
  const { id, collection } = action.payload

  const uniqueId = makeUniqueId({ id, collection })

  state.deleting[uniqueId] = false
  state.deleteError[uniqueId] = false
  delete state.fetching[uniqueId]
  delete state.error[uniqueId]
  delete state.success[uniqueId]
}

function deleteCacheFailure (state, action) {
  const { id, collection } = action.payload
  const uniqueId = makeUniqueId({ id, collection })

  state.deleting[uniqueId] = false
  state.deleteError[uniqueId] = true
}

const cache = createSlice({
  name: 'cache',
  initialState: {
    open: false,
    collectionId: null,
    deleting: {},
    deleteError: {},
    fetching: {},
    error: {},
    items: {},
    success: {}
  },
  reducers: {
    closeCacheContainer,
    openCacheContainer,
    fetchCacheRequest,
    fetchCacheSuccess,
    fetchCacheFailure,
    deleteCacheRequest,
    deleteCacheSuccess,
    deleteCacheFailure
  }
})

export default cache
