// @flow

import { ofType } from 'redux-observable'
import { map, catchError } from 'rxjs/operators'
import { of } from 'rxjs'
import type { Observable } from 'rxjs'

import type { Action, Epic } from '../types'
import catalogDuck from './catalogDuck'

const removeAllCollectionsEpic: Epic = (
  action$: Observable<Action>,
  state$: mixed,
  firestore: Function
) =>
  action$.pipe(
    ofType(catalogDuck.actions.removeAllCollectionsRequest),
    map(async ({ payload }) => {
      const { uid } = state$.value.firebase.auth

      const collections = await firestore
        .collection('users')
        .doc(uid)
        .collection('collections')
        .get()

      collections.docs.forEach(async doc => {
        await firestore
          .collection('users')
          .doc(uid)
          .collection('collections')
          .doc(doc.id)
          .delete()
      })

      return payload
    }),
    map(result => catalogDuck.actions.removeAllCollectionsSuccess(result)),
    catchError(error => {
      console.warn(error.message)
      return of(catalogDuck.actions.removeAllCollectionsFailure(error.message))
    })
  )

export default removeAllCollectionsEpic
