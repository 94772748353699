// @flow

import { createSlice } from 'redux-starter-kit'

function addSource (state, action) {
  const { id, collectionId, mosaic = false } = action.payload
  if (!id) {
    return state
  }

  const source = {
    id,
    collectionId,
    render: true,
    mosaic
  }

  return { ...state, [id]: source }
}

function removeSource (state, action) {
  const { id, collectionId } = action.payload
  const source = {
    id,
    collectionId,
    render: false
  }

  return { ...state, [id]: source }
}

function removeMosaicSources (state, action) {
}

function toggleSource (state, action) {
  const { id, collectionId, mosaic = false } = action.payload

  const prevSource = state[id]
  const source =
    prevSource && prevSource.render
      ? {
          id,
          collectionId,
          mosaic,
          render: false
        }
      : {
          id,
          collectionId,
          mosaic,
          render: true
        }

  return { ...state, [id]: source }
}

const sources = createSlice({
  name: 'sources',
  initialState: {},
  reducers: {
    addSource,
    removeSource,
    removeMosaicSources,
    toggleSource
  }
})

export default sources
