function deserialize (geometry) {
  if (!geometry) {
    return null
  }
  const { serialized } = geometry

  const deserializedGeometry = serialized
    ? { ...geometry, coordinates: JSON.parse(geometry.coordinates) }
    : geometry

  return deserializedGeometry
}

export function deserializeItem (item) {
  const { geometry } = item
  const deserializedGeometry = deserialize(geometry)

  return { ...item, geometry: deserializedGeometry }
}

export default deserialize
