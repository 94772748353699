// @flow

import { createSlice } from 'redux-starter-kit'

const initialState = {
  polaris: false,
  shipFormOpen: true,
  ships: {},
  ship: {},
  fetching: {},
  success: {},
  error: {},
  editing: {},
  editSuccess: {},
  editError: {}
}

function setShipFormOpen (state, action) {
  return { ...state, shipFormOpen: true }
}

function setShipFormClose (state, action) {
  return { ...state, shipFormOpen: false }
}

function togglePolaris (state, action) {
  const { polaris } = state

  return { ...state, polaris: !polaris }
}

function editShipRequest (state, action) {
  const ship = action.payload
  const { id } = ship

  state.ship = ship
  state.ships[id] = ship
  state.editing[id] = true
  state.editError[id] = false
  state.editSuccess[id] = false
}

function editShipSuccess (state, action) {
  const { id } = action.payload

  state.editing[id] = false
  state.editError[id] = false
  state.editSuccess[id] = true
}

function editShipFailure (state, action) {
  const { id } = action.payload

  state.editing[id] = false
  state.editError[id] = true
  state.editSuccess[id] = false
}

const catalog = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    togglePolaris,
    setShipFormOpen,
    setShipFormClose,
    editShipRequest,
    editShipSuccess,
    editShipFailure
  }
})

export default catalog
