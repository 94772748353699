// @flow

import { ofType } from 'redux-observable'
import { map, merge, mergeMap, catchError } from 'rxjs/operators'
import { of } from 'rxjs'
import type { Observable } from 'rxjs'

import deleteItemFromCache from '../utils/deleteItemFromCache'
import type { Action, Epic } from '../types'
import cache from './cacheDuck'

const deleteCacheEpic: Epic = (action$: Observable<Action>) =>
  action$.pipe(
    ofType(cache.actions.deleteCacheRequest),
    mergeMap(({ payload }) => deleteItemFromCache(payload)),
    map(result => cache.actions.deleteCacheSuccess(result)),
    catchError((error, caught) => {
      console.warn(error.message)
      return merge(of(cache.actions.deleteCacheFailure(error.message)), caught)
    })
  )

export default deleteCacheEpic
