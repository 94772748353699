import { createSelector, createIdSelector } from 'redux-views'

import deserialize from '../utils/deserialize'

export const getPropCollectionId = createIdSelector(
  ({ collectionId }) => collectionId
)

export const getPropCollectionIds = createIdSelector(
  ({ collectionIds }) => collectionIds
)

const getPropItemId = createIdSelector(({ itemId }) => itemId)

const getPropId = createIdSelector(({ id }) => id)

export const getData = state => state.firestore.data

export const getUserId = state => {
  const userId =
    state.firebase && state.firebase.auth && state.firebase.auth.uid

  return userId
}

export const getUserCollections = createSelector(
  [getData, getUserId],
  (data, userId) => {
    if (!userId) {
      return {}
    }

    const userCollections =
      data.users && data.users[userId] && data.users[userId].collections

    return userCollections || {}
  }
)

export const getUserLabels = createSelector(
  [getData, getUserId],
  (data, userId) => {
    if (!userId) {
      return {}
    }

    const userLabels =
      data.users && data.users[userId] && data.users[userId].labels

    return userLabels || {}
  }
)

export const getSharedCollections = createSelector(
  [getData],
  data => {
    return data.layers || {}
  }
)

export const getCollections = createSelector(
  [getSharedCollections, getUserCollections],
  (sharedCollections, userCollections) => {
    const collections = { ...sharedCollections, ...userCollections }

    return collections
  }
)

export const getCollection = createSelector(
  [getCollections, getPropCollectionId],
  (collections, collectionId) => {
    const collection = collections && collections[collectionId]

    return collection
  }
)

export const getCollectionLabel = createSelector(
  [getCollection, getUserLabels, getPropCollectionId],
  (collection, userLabels, collectionId) => {
    const { label } = collection
    const userLabel = userLabels[collectionId] && userLabels[collectionId].label

    return userLabel || label
  }
)

export const getCollectionType = createSelector(
  [getCollection, getPropCollectionId],
  (collection, collectionId) => {
    if (!collection) {
      return null
    }

    const { type } = collection

    return type
  }
)

export const getIsCollectionPolaris = createSelector(
  [getCollectionType, getPropCollectionId],
  (collectionType, collectionId) => {
    const isCollectionPolaris = ['ice-chart'].includes(collectionType)

    return isCollectionPolaris
  }
)

export const getItem = createSelector(
  [getData, getUserId, getPropItemId, getPropCollectionId],
  (data, userId, itemId, collectionId) => {
    if (!collectionId) {
      return null
    }
    if (data[collectionId]) {
      return data[collectionId][itemId]
    } else {
      //
      const items = data && data[collectionId] && data.users[collectionId].items
      if (!items) {
        return {}
      }
      const item = items[itemId]
      if (!item) {
        return null
      }

      const { geometry } = item
      const deserializedGeometry = deserialize(geometry)

      return { ...item, geometry: deserializedGeometry, test: true }
    }
  }
)

export function returnItems ({ data, userId, collectionId }) {
  const items = data[collectionId]

  return items
}

// TODO return all items for collectionId
export const getItems = createSelector(
  [getData, getUserId, getPropCollectionId],
  (data, userId, collectionId) => {
    const items = returnItems({ data, userId, collectionId })

    return items
  }
)

export const getItemsForCollections = createSelector(
  [getData, getUserId, getPropCollectionIds],
  (data, userId, collectionIds) => {
    if (collectionIds.length === 0) {
      return {}
    }
    const itemsObject = collectionIds.reduce((acc, id) => {
      acc[id] = returnItems({ data, userId, collectionId: id })

      return acc
    }, {})

    return itemsObject
  }
)

export const getLatestItemId = createSelector(
  [getCollection],
  collection => {
    const collectionIds = collection ? Object.keys(collection) : []
    const latestItemId = collectionIds[collectionIds.length - 1]

    return latestItemId
  }
)

export const getUserData = createSelector(
  [getData, getUserId],
  (data, userId) => {}
)

export const getCatalogItems = createSelector(
  [getData, getCollections, getPropCollectionId],
  (data, collections, collectionId) => {
    const collection = collections[collectionId]
    if (!collection) {
      return {}
    }
    const { userId } = collection

    const items = userId
      ? data.users &&
        data.users[userId] &&
        data.users[userId].collections[collectionId].items
      : data[collectionId]

    return items || {}
  }
)

export const getAssetIds = createSelector(
  [getPropCollectionId, getCollections],
  (collectionId, collections) => {
    if (!collections) {
      return null
    }
    const collection = collections[collectionId]
    if (!collection) {
      return null
    }
    const { assetIds } = collection
    return assetIds
  }
)

export const getPaint = createSelector(
  [getPropCollectionId, getCollections],
  (collectionId, collections) => {
    const collection = collections[collectionId]

    if (!collection) {
      return undefined
    }

    const { paint } = collection

    return paint && JSON.parse(paint)
  }
)

export const getExpanded = state => state.catalog.expanded

export const getExpandedById = createSelector(
  [getExpanded, getPropId],
  (expanded, id) => {
    const isExpanded = expanded[id]

    return !!isExpanded
  }
)

export const getSelected = state => state.catalog.selected

export const getSelectedIdById = createSelector(
  [getSelected, getPropId],
  (selected, id) => {
    const selectedId = selected[id]

    return selectedId
  }
)

export const getCollectionBbox = createSelector(
  [getCollection, getPropCollectionId],
  (collection, collectionId) => {
    if (!collection) {
      return null
    }
    const { bbox } = collection

    return bbox
  }
)
