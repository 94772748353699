// @flow

import React from 'react'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'

import firebase from '../redux/firebase'

const reactReduxFirebaseConfig = {
  userProfile: 'users',
  // enableLogging: true,
  useFirestoreForProfile: true
  // useFirestoreForStorageMeta: true
}

function FirebaseProvider ({ store, children }) {
  const reactReduxFirebaseProps = {
    firebase,
    config: reactReduxFirebaseConfig,
    dispatch: store.dispatch,
    createFirestoreInstance
  }

  return (
    <ReactReduxFirebaseProvider {...reactReduxFirebaseProps}>
      {children}
    </ReactReduxFirebaseProvider>
  )
}

export default FirebaseProvider
