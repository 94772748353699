import { createSlice } from 'redux-starter-kit'

function closeEditContainer (state, action) {
  return { ...state, open: false }
}

function openEditContainer (state, action) {
  const { collectionId } = action.payload

  return { ...state, open: true, collectionId }
}

function editRequest (state, action) {
  const { id } = action.payload

  state.fetching[id] = true
  state.error[id] = false
  state.success[id] = false
}

function editSuccess (state, action) {
  const { id } = action.payload

  state.fetching[id] = false
  state.error[id] = false
  state.success[id] = true
}

function editFailure (state, action) {
  const { id } = action.payload

  state.fetching[id] = false
  state.error[id] = true
  state.success[id] = false
}

const edit = createSlice({
  name: 'edit',
  initialState: {
    open: false,
    collectionId: null,
    deleting: {},
    deleteError: {},
    fetching: {},
    error: {},
    items: {},
    success: {}
  },
  reducers: {
    closeEditContainer,
    openEditContainer,
    editRequest,
    editSuccess,
    editFailure
  }
})

export default edit
