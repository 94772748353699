// @flow

import { ofType } from 'redux-observable'
import { map, catchError } from 'rxjs/operators'
import { of } from 'rxjs'
import type { Observable } from 'rxjs'

import type { Action, Epic } from '../types'
import catalogDuck from './catalogDuck'

const editCollectionsEpic: Epic = (
  action$: Observable<Action>,
  state$: mixed,
  firestore: Function
) =>
  action$.pipe(
    ofType(catalogDuck.actions.editCollectionRequest),
    map(async ({ payload }) => {

      return payload
    }),
    map(result => catalogDuck.actions.editCollectionSuccess(result)),
    catchError(error => {
      console.warn(error.message)
      return of(catalogDuck.actions.editCollectionFailure(error.message))
    })
  )

export default editCollectionsEpic
