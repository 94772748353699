// @flow

import { ofType } from 'redux-observable'
import { map, merge, mergeMap, catchError } from 'rxjs/operators'
import { of } from 'rxjs'
import type { Observable } from 'rxjs'

import addItemToCache from '../utils/addItemToCache'
import type { Action, Epic } from '../types'
import cache from './cacheDuck'

const cacheEpic: Epic = (action$: Observable<Action>) =>
  action$.pipe(
    ofType(cache.actions.fetchCacheRequest),
    mergeMap(({ payload }) => addItemToCache(payload)),
    map(result => {
      if (result.error) {
        return cache.actions.fetchCacheFailure(result)
      } else {
        return cache.actions.fetchCacheSuccess(result)
      }
    }),
    catchError((error, caught) => {
      console.log({ caught })
      console.warn(error.message)
      return merge(of(cache.actions.fetchCacheFailure(error.message)), caught)
    })
  )

export default cacheEpic
