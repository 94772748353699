// @flow

import { createSlice } from 'redux-starter-kit'
import geoViewport from '@mapbox/geo-viewport'

import { defaultViewport } from '../config'

const initialState = {
  loading: false,
  ...defaultViewport
}

function setLoaded (state, action) {
  const { payload } = action

  state.loaded = payload
}

function changeViewport (state, action) {
  const { payload } = action

  return { ...state, ...payload }
}

function fitBounds (state, action) {
  const { bbox } = action.payload

  const width = window.innerWidth
  const height = window.innerHeight

  const {
    center: [longitude, latitude],
    zoom
  } = geoViewport.viewport(bbox, [width, height])

  return { ...state, latitude, longitude, zoom: zoom - 1 }
}

const map = createSlice({
  name: 'map',
  initialState,
  reducers: {
    changeViewport,
    fitBounds,
    setLoaded
  }
})

export default map
