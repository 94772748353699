// @flow

import React, { Fragment } from 'react'
import { Route } from 'react-router-dom'
import loadable from '@loadable/component'

const SignInRoute = loadable(() => import('../routes/SignInRoute'))
const SignUpRoute = loadable(() => import('../routes/SignUpRoute'))
const ResetPasswordRoute = loadable(() =>
  import('../routes/ResetPasswordRoute')
)

function NotAuthenticatedNavigationContainer () {
  return (
    <Fragment>
      <Route path='/' exact component={SignInRoute} />
      <Route path='/signup' component={SignUpRoute} />
      <Route path='/resetpassword' component={ResetPasswordRoute} />
    </Fragment>
  )
}

export default NotAuthenticatedNavigationContainer
