// @flow

import { createSelector, createIdSelector } from 'redux-views'
import { firestoreDataSelector } from 'redux-firestore'

import { getUserId } from '../redux/catalogSelector'

const getPropCollectionId = createIdSelector(({ collectionId }) => collectionId)
const getPropCollectionUserId = createIdSelector(
  ({ collectionUserId }) => collectionUserId
)
const getPropType = createIdSelector(({ type }) => type)

export function usersQuery () {
  return {
    collection: 'users'
  }
}

function userQuery ({ userId }) {
  return {
    collection: 'users',
    doc: userId
  }
}

function userCollectionsQuery ({ userId }) {
  return {
    collection: 'users',
    doc: userId,
    subcollections: [{ collection: 'collections' }]
  }
}

function userCollectionItemsQuery ({ userId, collectionId }) {
  return {
    collection: 'users',
    doc: userId,
    storeAs: collectionId,
    subcollections: [
      {
        collection: 'collections',
        doc: collectionId,
        subcollections: [
          {
            collection: 'items'
          }
        ]
      }
    ]
  }
}

function userLabelsQuery ({ userId }) {
  return {
    collection: 'users',
    doc: userId,
    subcollections: [{ collection: 'labels' }]
  }
}

export const getUsersQuery = createSelector(
  [getUserId],
  userId => {
    const query = usersQuery()

    return query
  }
)

export const getUserQuery = createSelector(
  [getUserId],
  userId => {
    const query = userQuery({ userId })

    return query
  }
)

export const getUserCollectionsQuery = createSelector(
  [getUserId],
  userId => {
    if (!userId) {
      return null
    }
    const query = userCollectionsQuery({ userId })

    return query
  }
)

export const getUserCollectionItemsQuery = createSelector(
  [getUserId, getPropCollectionId, getPropType],
  (userId, collectionId, type) => {
    if (!userId || !collectionId || type !== 'sentinel-1') {
      return null
    }
    const query = userCollectionItemsQuery({ userId, collectionId })

    return query
  }
)

export const getUserLabelsQuery = createSelector(
  [getUserId],
  userId => {
    if (!userId) {
      return null
    }
    const query = userLabelsQuery({ userId })

    return query
  }
)

export const getProjects = state => state.firebase.users

const getProjectsId = ({ userId }) =>
  firestoreDataSelector({
    collection: 'users',
    doc: userId
  })

const getState = state => state

export const getProjectsSelector = createSelector(
  [getUserId, getState],
  (userId, state) => {
    if (!userId) {
      return null
    }
    const selector = getProjectsId({ userId })(state)

    return selector
  }
)

export const getUserCollectionsFirestore = ({ userId }) => state =>
  state.firebase.users &&
  state.userbase.users[userId] &&
  state.firebase.users[userId].collections

export const getUserCollections = createSelector(
  [getUserId, getState],
  (userId, state) => {
    if (!userId) {
      return null
    }
    const selector = getUserCollectionsFirestore({ userId })(state)

    return selector
  }
)

export const getUserCollectionItems = createSelector(
  [getUserId, getState, getPropCollectionId],
  (userId, state) => {
    if (!userId) {
      return null
    }
    const selector = getUserCollectionsFirestore({ userId })(state)

    return selector
  }
)

function collectionQuery ({ collectionId, collectionUserId }) {
  // console.log('collectionQuery')
  // console.log(collectionId)
  const query = collectionUserId
    ? {
        collection: 'users',
        doc: collectionUserId,
        subcollections: [
          {
            collection: 'collections',
            doc: collectionId
          }
          // {
          //   collection: 'labels'
          // }
        ]
      }
    : {
        collection: collectionId
      }

  return query
}

export const getCollectionQuery = createSelector(
  [getPropCollectionId, getPropCollectionUserId],
  (collectionId, collectionUserId) => {
    const query = collectionQuery({ collectionId, collectionUserId })

    return query
  }
)
