// @flow

import React, { Fragment } from 'react'
import { Route } from 'react-router-dom'
import loadable from '@loadable/component'

const MapRoute = loadable(() => import('../routes/MapRoute'))

function AuthenticatedNavigationContainer () {
  return (
    <Fragment>
      <Route path='/' component={MapRoute} />
    </Fragment>
  )
}

export default AuthenticatedNavigationContainer
