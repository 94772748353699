// @flow

import { createSlice } from 'redux-starter-kit'

function setCollectionIndex (state, action) {
  const { collectionId, index } = action.payload

  return { ...state, [collectionId]: index }
}

const collection = createSlice({
  name: 'collection',
  initialState: {},
  reducers: {
    setCollectionIndex
  }
})

export default collection
