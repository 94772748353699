const art = `%c
                  ＼(＾O＾)／
   _____       _____ ____  _____  ______ 
  / ____|     / ____/ __ \\|  __ \\|  ____|
 | |   ______| |   | |  | | |__) | |__   
 | |  |______| |   | |  | |  _  /|  __|  
 | |____     | |___| |__| | | \\ \\| |____ 
  \\_____|     \\_____\\____/|_|  \\_\\______|
¸.·´¯·.´¯·.¸¸.·´¯·.¸¸.·´¯·.´¯·.¸¸.·´¯·.¸¸.·´
`

export default art
